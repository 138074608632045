import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import React from 'react';

const MAX_STR_LENGTH = 50;
export function formatSpend(spend: number): string {
  return spend && formatCurrency(getNormalizedSpend(spend));
}
export function getNormalizedSpend(spend:number ):number {
  return spend / 10_000;
}

// export function formatPercentage(value: number): string {
//   return (Math.round(value * 100) / 100).toFixed(2) + '%';
// }

export function formatPercentage(value: number): string {
  if(!value) return '';
  return (value * 100).toFixed(2) + '%';
}

export function calculatePercentage(part: number, total: number): string {
  return total > 0 ? ((part / total) * 100).toFixed(2) : '0.00';
}

export function formatCurrency(amount: number | undefined): string {
  if (amount === undefined || amount === null || isNaN(amount)) {
    return '$0';
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(amount);
}

export const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export function formatValue(value: any,isCurrency:boolean=false): string|React.ReactNode {
  //IMAGES
  if(typeof value === 'string') {
    const imageMatch = value.match(/!\[[^\]]*\]\((?<filename>.*?)(?=\"|\))(?<alt>\".*\")?\)/);
    if(imageMatch) {
      const {filename,alt} = imageMatch.groups;
      return <picture
        onMouseEnter={(e) => {
          (e.currentTarget.children[1] as any).style.display = 'block';
        }}
        onMouseLeave={(e) => {
          (e.currentTarget.children[1] as any).style.display = 'none';
        }}
        // onMouseOver={(e) => {
        //   (e.currentTarget.children[1] as any).style.top = (e.clientY+3) + 'px';
        //   (e.currentTarget.children[1] as any).style.left = (e.clientX+3) + 'px';
        // }}
      ><img
        src={filename}
        alt={alt}
        style={{maxWidth:'100%',maxHeight:'30px'}}
      />
      <img
        src={filename}
        alt={alt}
        style={{display:'none'}}
      />
      </picture>
    }
    //LINKS
    if (value.match(/(?:__|[*#])|\[(.*?)\]\(.*?\)/)) {
      //parse out link and title of link
      const match = value.match(/\[(.*?)\]\((.*?)\)/);
      if(match) {
        let [,title,url] = match;
        let fullTitle = title;
        if(title.length > MAX_STR_LENGTH) {
          title = title.slice(0,MAX_STR_LENGTH) + '...';
        }
        return <a href={url} title={fullTitle} target={'_blank'} rel="noreferrer">{
          title
        }</a>
      }
    }
  }

  if(typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if(value instanceof NamedNode) {
    console.warn('NamedNode:',value);
    value = value.uri;
  }
  if(value instanceof Shape) {
    value = value.uri;
  }
  if(typeof value === 'number') {
    value = isCurrency ? formatCurrency(value) : formatNumberFrontend(value);
  }
  if(value instanceof ShapeSet) {
    //convert value to string, which will then follow automatic shortening of string
    value = value.map(v => v.uri).join(", ");
  }
  if(typeof value === 'string') {
    // Check if the string contains a link pattern (e.g., [text](url))
    if (value.match(/\[(.*?)\]\((.*?)\)/)) {
      return value; // Skip truncation for strings with links
    }
    if(value.length > MAX_STR_LENGTH) {
      let fullValue = value;
      return <span title={fullValue}>{value.slice(0,MAX_STR_LENGTH) + '...'}</span>;
    }
    return value;
  }
  if(Array.isArray(value)) {
    if(value.length > 0) {
      return value.join(', ');
    }
  }
  else if (typeof value === 'object' && value !== null) {
    console.log('converting object to json:',value);
    return value.formatted; // Or access specific properties you want to render.
  }
  return value;
}
export function formatDate(value?: string): string {
  return `${new Date(value || new Date()).toLocaleDateString('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: '2-digit',
  })}`;
}

/**
 * Only use this on the frontend, because it will mess up sorting when used on the backend
 * @param number
 */
export function formatNumberFrontend(number: number): string {
  return number && number?.toLocaleString();
}

export function formatLink(text: string, link: string): string {
  return `[${text?.toString().replace(/,/g,'')}](${link})`;
}
export function formatImage(src:string,text: string=''): string {
  if(!src) return '';
  //return markdown image, with optional text. Note, images can have alt and description, here we only use alt
  return `![](${src} "${text}")`
}


/**
 * Test Data from https://docs.google.com/spreadsheets/d/1o24SG4Inewi-YqHPeMKFNXwrmsh1KuFr6TfM1HFgP3g/edit?gid=0#gid=0
 * This data for generate quicklaunch data
 */
export const QUICKLAUNCH_DATA = [{
  link: "https://semantu.com",
  businessName: "Semantu",
  headlines: [
    {
      headline:'Unlock AI for Smarter Business Growth',
      description:'Unlock Power'
    },
    {
      headline:'Tailored Tools for Maximum Impact',
      description:'Tailored Tools'
    },
    {
      headline:'Effortless Data Insights with AI Simplicity',
      description:'Effortless Data'
    },
    {
      headline:'Take Control of Your Data with Powerful AI',
      description:'Take Control'
    }
  ],
  sublines: [
    {
      subline: 'Transform data into insights with Semantu’s AI tools, driving better decisions and business success.',
      description: 'Transform data',
    },
    {
      subline: 'Leverage Semantu’s technology to streamline operations, boost productivity, and elevate your strategy.',
      description: 'Streamline operations',
    },
    {
      subline: 'Cutting edge technology to create deeper partnerships with a robust collaborative framework.',
      description: 'Deeper Partnerships',
    },
    {
      subline: 'Semantu equips your business with powerful tools to stay ahead of the competition.',
      description: 'Semantu equips',
    }
  ],
  actions: ['Learn More', 'Start Now', '', ''],
  images: ['ai-woman', 'man-at-desk', 'handshake', ''],
},
  {
    link: "https://lincd.org",
    businessName: "LINCD",
    headlines: [
      {
        headline:'Open-Source Collaboration for a Better Future',
        description:'Building better'
      },
      {
        headline:'Connect, Innovate, and Impact with LINCD',
        description:'Impact with LINCD'
      },
      {
        headline:'Powering Impactful Solutions with Open-Source Tech',
        description:'Powering Impact'
      },
      {
        headline:'Supercharge Your Mission with Open-Source Technology',
        description:'Supercharge'
      }
    ],
    sublines: [
      {
        subline: 'LINCD empowers organizations to create scalable, impactful solutions for a more sustainable world.',
        description: 'Empowers',
      },
      {
        subline: 'Join a global network using LINCD’s open-source tech to drive change and solve pressing challenges.',
        description: 'Global Network',
      },
      {
        subline: 'LINCD’s protocol equips organizations with tools to deliver sustainable solutions effectively.',
        description: 'Equips Impact',
      },
      {
        subline: 'LINCD empowers organizations to grow impact using scalable, innovative tech for the greater good.',
        description: 'Greater Good',
      }
    ],
    actions: ['Learn More', 'Start Now', '', ''],
    images: ['protecting_earth', 'earth-in-hands', 'woman-at-computer', ''],
  }];
