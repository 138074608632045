import React,{useEffect,useState} from 'react';
import {NamedNode} from 'lincd/lib/models';
import {Shape} from 'lincd/lib/shapes/Shape';
import {CustomColumn,DynamicObject} from '../utils';
import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {formatNumberFrontend,formatValue} from '../utils/data';

export function useCustomColumns(instances,customColumnConfig?:{[key:string]:{className?:(value:any,row:DynamicObject)=>string}}) {
  const [customColumns, setCustomColumns] = useState<CustomColumn[] | undefined>(undefined);
  useEffect(() => {
    if (instances && instances.length > 0) {

      // Collect all unique keys from all instances
      const allKeys = new Set<string>();

      instances.forEach((instance) => {
        Object.keys(instance).forEach((key) => {
          if(key !== 'id') {
            allKeys.add(key)
          }
        });
      });

      // Generate columns from all unique keys
      const columns = Array.from(allKeys)
        .map((key) => {
          return {
            label: key,
            property: {label: key
                .replace(/([a-z])([A-Z])/g, '$1 $2')
                .replace(/^./, (str) => str.toUpperCase())},
            meta:{
              className: (value,row: DynamicObject) => {
                if(customColumnConfig && customColumnConfig[key] && customColumnConfig[key].className) {
                  return customColumnConfig[key].className(value,row);
                }
                return '';
              }
            },
            renderCell: (row: DynamicObject) => {
              const value = row[key];
              
              // split value by comma to display multiple values
              // we need to make sure the value is a string before split
              // currently we use on objects field on Approval and Optimization Report page
              // e.g. [001F](.../AdSet/316659354150273), [003F](.../AdSet/316659354247310)
              // const getValues = value && typeof value === 'string' && value?.split(',');
              // if (getValues && getValues.length > 1) {
              //   return (
              //     <div>
              //       {getValues.map((val: string, index: number) => {
              //         const format = formatValue(val, key.startsWith('$'));
              //         // remove comma from last value
              //         return (
              //           <span key={index}>
              //             {format}
              //             {index < getValues.length - 1 && ', '}
              //           </span>
              //         );
              //       })}
              //     </div>
              //   );
              // } else {
                return formatValue(value, key.startsWith('$'));
              // }
            },
          };
        });

      setCustomColumns(columns);
    }
  }, [instances])

  return customColumns;
}