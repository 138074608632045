import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Ad, AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {LandingPage} from 'titan-ads/lib/shapes/LandingPage';
import {DashboardContext} from '../contexts/Dashboard';
import {useSuspense} from '../utils';
import './RefreshQuoraData.scss';
import {default as style} from './RefreshQuoraData.scss.json';
import {Literal} from 'lincd/lib/models';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';
import { packageName } from '../package';
import { brandAccount, headline } from 'titan-ads/lib/ontologies/titan-ads';
import { cl } from 'lincd/lib/utils/ClassNames';

interface RefreshQuoraDataProps {
  qAccounts: {read(): Iterable<BrandAccount>};
  disabled?: boolean;
  showLabel?: boolean;
  placeholder?: string;
  className?: string;
}

export function BrandAccountSelector({
  qAccounts,
  disabled = false,
  showLabel = true,
  placeholder = 'Choose a brand account',
  className,
}: RefreshQuoraDataProps) {
  const {accountsLoading, curAccount, setCurAccount, startCampaignsTransition} =
    useContext(DashboardContext);

  const auth = useAuth();
  let accounts: BrandAccount[] = [...(qAccounts.read() || [])];
  const [headLineOptions, setHeadLineOptions] = useState<{ brandAccount: string; subHeadlines: string[] }[]>([]);

  //make sure never to show the semantu or lincd accounts in production
  //unless the user is a semantu employee
  if(!auth.userAccount.email.includes("semantu.com")) {
    accounts = accounts.filter((acc) => !acc.name.toLowerCase().includes("Semantu") && !acc.name.toLowerCase().includes("lincd"));
  }
  // //filter out accounts that come from the cronjob, but not from the BrandAccount section of the dashboard
  accounts = accounts.filter((acc) => acc.quoraAccount);

  // //only show the semantu and lincd accounts in development, so we don't mess up their accounts by accident
  // if(process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'remote_dev') {
  //   accounts = accounts.filter((acc) => acc.name.toLowerCase().includes("semantu") || acc.name.toLowerCase().includes("lincd"));
  // }

  useEffect(() => {
    updateCampaigns();
  }, [curAccount]);

  const handleOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      setCurAccount(
        BrandAccount.getFromURI(e.target.value as string)
        // BrandAccount.getLocalInstancesByType().find(
        //   (ba) => ba.uri === (e.target.value as string),
        // ),
      );
    }
  };

  // Load all the campaigns and adsets whenever the account is changed
  const updateCampaigns = () => {
    startCampaignsTransition(() => {
      useSuspense(Campaign.loadAll());
      useSuspense(AdSet.loadAll());
      // useSuspense(LandingPage.loadAll());
    });
  };
  const renderAccountOption = (acc: BrandAccount) => {
    // return acc.offers.map((offer: Literal) => {
    return (
      <option disabled={!acc.quoraAccount} value={acc.uri} key={acc.uri}>
        {/*short name | Publishing brand name - Account/offer name or Multi-offer*/}
        {`${acc.shortName} | ${acc.name} - ${acc.offer}`}
      </option>
    );
    // });
  };

  return (
    <>
      {showLabel && (
        <label htmlFor="choose-brand">
          <h3>Select Brand Account:</h3>
        </label>
      )}
      <div>
        <select
          data-cy="choose-brand"
          name="brand"
          disabled={disabled}
          value={curAccount?.uri || placeholder}
          onChange={handleOnChange}
          className={cl(style.dropdown, className)}
        >
          <option>{placeholder}</option>
          {accounts &&
            accounts.map((acc) => {
              return renderAccountOption(acc);
            })}
        </select>
      </div>
    </>
  );
}

BrandAccountSelector.Loading = () => (
  <>
    <label>
      <h3>Select Brand:</h3>
    </label>
    <select className={style.dropdown}>
      <option>Loading...</option>
    </select>
  </>
);


