import React, { forwardRef, useImperativeHandle } from 'react';
import './TableView.scss';
import styles from './TableView.scss.json';
import { Spinner } from './Spinner';
import { DateRange } from 'react-day-picker';
import { formatCurrency, formatSpend, formatNumberFrontend } from '../utils/data';

const TableView = forwardRef<HTMLDivElement, { data: any[]; reportType: string, isLoading: boolean, account: string, selectedDate: Date | DateRange }>(
  ({ data, reportType, isLoading, account, selectedDate }, ref) => {

    // Format the date range for the report
    const formatDateRange = (range: DateRange) => {
      if (!range.from || !range.to) return '';
      const formatSingleDate = (d: Date) => {
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear().toString().slice(-2)}`;
      }
      return `${formatSingleDate(range.from)} - ${formatSingleDate(range.to)}`;
    }

    // Get the target date range
    const targetDate = selectedDate && 'from' in selectedDate && 'to' in selectedDate
      ? formatDateRange(selectedDate)
      : null;

    // Calculate the overall totals
    const overallTotals = data.reduce(
        (totals, row) => {
          totals.totalImpressions += row.metrics?.totalImpressions || 0;
          totals.totalClicks += row.metrics?.totalClicks || 0;
          totals.totalSpend += row.metrics?.totalSpend || 0;
          totals.totalSales += row.metrics?.totalSales || 0;
          totals.totalPayout += row.metrics?.totalPayout || 0;
          return totals;
        },
        { totalImpressions: 0, totalClicks: 0, totalSpend: 0, totalSales: 0, totalPayout: 0 }
    );

    return (
      <div ref={ref} className={styles.tableContainer}>
        {!isLoading && (
          <>
            {data.length > 0 &&
              <div className={styles.accountId}>
                <h1>{reportType === 'sales' ? 'Daily Sales Report' : 'Day Party Summary Report'}</h1>
                <div className={styles.identifier}>
                  <label>Brand Account | Quora Account</label>
                  <p>{account}</p>
                </div>
                <div className={styles.identifier}>
                  <label>Day Report:</label>
                  <p>{selectedDate instanceof Date ? selectedDate.toLocaleDateString() : targetDate}</p>
                </div>
              </div>
            }
          </>
        )}
        {reportType === 'daypartingview' ? (
          <>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Traffic</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No data available
                  </td>
                </tr>
              ) : (
                data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.metric}</td>
                    <td>{row.quoraPL}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          </>
        ) : (
          <table className={styles.table}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Impressions</th>
                <th>Clicks</th>
                <th>Spend</th>
                <th>Sales</th>
                <th>Payout</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center' }}>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              ) : data.length === 0 ? (
                <tr>
                  <td colSpan={6} style={{ textAlign: 'center' }}>
                    No data available
                  </td>
                </tr>
              ) : 
              data.map((row, index) => (
                <tr key={index}>
                  <td>{row.date}</td>
                  <td>{formatNumberFrontend(row.metrics.totalImpressions)}</td>
                  <td>{formatNumberFrontend(row.metrics.totalClicks)}</td>
                  <td>{formatSpend(row.metrics.totalSpend)}</td>
                  <td>{formatNumberFrontend(row.metrics.totalSales)}</td>
                  <td>{formatCurrency(row.metrics.totalPayout)}</td>
                </tr>
              ))}
              {data.length > 0 && (
              <tr>
                <td><strong>Overall Totals</strong></td>
                <td><strong>{isLoading ? '0' : formatNumberFrontend(overallTotals.totalImpressions)}</strong></td>
                <td><strong>{isLoading ? '0' : formatNumberFrontend(overallTotals.totalClicks)}</strong></td>
                <td><strong>{isLoading ? '0' : formatSpend(overallTotals.totalSpend)}</strong></td>
                <td><strong>{isLoading ? '0' : formatNumberFrontend(overallTotals.totalSales)}</strong></td>
                <td><strong>{isLoading ? '0' : formatCurrency(overallTotals.totalPayout)}</strong></td>
              </tr>
              )}
            </tbody>
          </table>
        )}

      </div>
    );
  });

export default TableView;
