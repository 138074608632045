import {NamedNode} from 'lincd/lib/models';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {AdInputPartial, InputType} from 'titan-ads/lib/types';
import {
  getInputsByPlatform,
  getPartialKey,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {DashboardContext} from '../contexts/Dashboard';
import {
  DefaultCampaignDropdownOption,
  DefaultCampaignDropdownOptions,
  DropdownState,
  GroupID,
} from '../types';
import {AdInput} from './AdInput';
import './InputGroup.scss';
import styles from './InputGroup.scss.json';

type CampaignDropdown = Campaign | DefaultCampaignDropdownOption;

interface CampaignColumnProps {
  groupId: GroupID;
  handleNextPriority: (
    curPriority?: number,
    setToCurPriority?: boolean,
  ) => void;
  // For if the input group was duplicated
  initialCampaign?: CampaignDropdown;
}

export default function CampaignColumn({
  groupId,
  handleNextPriority,
  initialCampaign = 'Choose a campaign',
}: CampaignColumnProps) {
  const [dropdownState, setDropdownState] = useState<DropdownState>(
    initialCampaign === 'Choose a campaign' ? 'not_chosen' : 'chosen',
  );
  const [selectedCampaign, setSelectedCampaign] =
    useState<CampaignDropdown>(initialCampaign);

  const {curAccount: account} = useContext(DashboardContext);
  const {
    handleEditAdSet,
    handleEditCampaign,
    handleMarkAsCreatingAdSet,
    handleMarkAsCreatingCampaign,
    inputGroups,
  } = useContext(InputsContext);
  const {
    adSet,
    campaign,
    creatingAdSet,
    creatingCampaign,
    inputs,
    pasted,
    platform,
  } = inputGroups[groupId];

  // Update the context when the selected campaign in the dropdown changes
  useEffect(() => {
    // Didn't choose a default option
    const choseAnyCampaign = selectedCampaign instanceof Campaign;

    if (selectedCampaign === 'Create a new campaign') {
      handleMarkAsCreatingCampaign(groupId);

      const newCampaign = new Campaign(account);
      setSelectedCampaign(newCampaign);
    }

    if (choseAnyCampaign) {
      setDropdownState('chosen');
      handleNextPriority(0);
      handleEditCampaign(groupId, selectedCampaign);

      // Clear any chosen adset
      if (creatingAdSet) {
        handleMarkAsCreatingAdSet(groupId, false);
      }
      handleEditAdSet(groupId, undefined);
    }
  }, [selectedCampaign]);

  // The options to display in the campaign dropdown
  const campaignOptions: CampaignDropdown[] = [
    'Choose a campaign',
    'Create a new campaign',
    ...account.campaigns.filter((campaign) => !campaign.isTemplate),
  ];

  // User selected an option from the campaign dropdown
  const handleCampaignChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newCampaignName = e.target.value as
      | DefaultCampaignDropdownOption
      | string;

    if (
      DefaultCampaignDropdownOptions.includes(
        newCampaignName as DefaultCampaignDropdownOption,
      )
    ) {
      setSelectedCampaign(newCampaignName as DefaultCampaignDropdownOption);
    } else {
      setSelectedCampaign(
        campaignOptions.find(
          (c) => c instanceof Campaign && c.identifier === newCampaignName,
        ),
      );
    }
  };

  const displayCampaignOption = (opt: CampaignDropdown, index) => {
    // Don't display the "Choose a campaign" option if a campaign's been chosen
    if (dropdownState !== 'not_chosen' && opt === 'Choose a campaign') return;
    // Don't display the "Create a campaign" option if a campaign is being created
    if (creatingCampaign && opt === 'Create a new campaign') return;

    if (!(opt instanceof Campaign)) {
      return <option key={index + opt.toString()}>{opt.toString()}</option>;
    }

    opt = opt as Campaign;

    if (opt.isTemplate) return;

    return <option key={index + opt.toString()}>{opt.identifier}</option>;
  };

  // Filter predicate
  const byCampaignColumnAndNotInternal = (p: AdInputPartial) =>
    !p.internal && p.column === 'campaign';
  const campaignInputs = getInputsByPlatform(platform).filter(
    byCampaignColumnAndNotInternal,
  );

  const chosePreexistingCampaign =
    !creatingCampaign && selectedCampaign instanceof Campaign && campaign;

  const anyDependentGroupsAdSet: boolean =
    Object.entries(inputGroups).some(
      ([gId, group]) =>
        gId !== groupId.toString() &&
        !group.creatingAdSet &&
        adSet?.equals(group.adSet),
    ) && adSet.namedNode.isTemporaryNode;

  return (
    <>
      <div className={`${styles.campaign} ${styles.required}`}>
        <label>Campaign Code</label>
        <select
          disabled={creatingCampaign || anyDependentGroupsAdSet}
          onChange={handleCampaignChange}
          value={
            selectedCampaign instanceof Campaign
              ? selectedCampaign.identifier
              : selectedCampaign
          }
        >
          {campaignOptions.map(displayCampaignOption)}
        </select>
      </div>
      {
        // User chose a pre-existing campaign:
        // - Inputs should be disabled, not editable
        chosePreexistingCampaign &&
          campaignInputs.map((p) => {
            let value: InputType;
            // TODO: Support nested shapePaths
            if (p.shapePath instanceof NamedNode) {
              value = selectedCampaign.getValue(p.shapePath);
            }
            const key = `${selectedCampaign.identifier}-${getPartialKey(p)}`;

            const conversionType =
              inputs[getPartialKeyByTitle(platform, 'Conversion Objective')];
            if (
              p.title === 'Conversion Event' &&
              conversionType !== 'Conversions'
            )
              return <></>;
            else if (p.title === 'Campaign') return <></>;

            if (!value && p.checked === undefined) {
              value = 'N/A';
            }

            return (
              <div key={key} className={styles.campaign}>
                <b>{p.title}:</b> {value.toString()}
              </div>
            );
          })
      }
      {
        // User is creating a new campaign:
        // - Allow the inputs to be editable
        creatingCampaign &&
          campaign &&
          campaignInputs.map((p) => {
            let className = styles.campaign;
            if (p.required) {
              className += ` ${styles.required}`;
            }

            const key = `${campaign.identifier}-${getPartialKey(p)}`;

            const conversionType =
              inputs[getPartialKeyByTitle(platform, 'Conversion Objective')];
            if (
              p.title === 'Conversion Event' &&
              conversionType !== 'Conversions'
            )
              return <React.Fragment key={key}></React.Fragment>;

            return (
              <AdInput
                className={className}
                groupId={groupId}
                handleNextPriority={handleNextPriority}
                id={getPartialKey(p)}
                inputData={p}
                key={key}
              />
            );
          })
      }
    </>
  );
}
