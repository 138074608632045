import React, {useEffect, useState, useCallback, useContext} from 'react'
import { AdSet } from "titan-ads/lib/shapes/TitanShapes";
import { NodeShape } from 'lincd/lib/shapes/SHACL';
import { TitanQueryConfig, loadCampaignData , isDateToday } from '../utils';
import { Spinner } from '../components/Spinner';
import {InstanceOverview} from '../components/InstanceOverview'
import { debounce } from 'lodash';
import {ThemeContext} from '../contexts/Theme';
import CampaignActionDropdown from '../components/CampaignActionDropdown';
import {useCustomColumns} from '../hooks/useCustomColumns';
import { useNavigate, Routes, useParams, useLocation } from 'react-router-dom';
import DatePickerFilter from '../components/DatePickerFilter';
import { FilterProps, TimeRangeProps } from '../types';
import dayjs from 'dayjs';
import { DashboardContext } from '../contexts/Dashboard';

interface DynamicObject {
  [key: string]: any;
}

interface CustomColumn {
  property?: { label?: string };
  label: string;
  renderCell: (row: any) => any; // Using 'any' for the row parameter
}

interface CampaignManagementProps {
  batchActions?: any; // Adjust the type as needed
}

function CampaignManagement({ batchActions }: CampaignManagementProps) {
  const { isLight } = useContext(ThemeContext); // Access the theme context
  const [instances, setInstances] = useState<DynamicObject[]>();
  const [sorting, setSorting] = React.useState<TitanQueryConfig['sortBy'][]>([]);
  const [pagination, _setPagination] = useState({ pageIndex: 0, pageSize: 10 }); // Pagination state
  const [totalItems, setTotalItems] = useState(0); // Total number of items on the server
  const [searchQuery, setSearchQuery] = useState('');
  const initialFilter = [
    { field: 'impressions', value: '0', operator: 'greater' }
  ];
  const [filterData, setFilterData] = useState<FilterProps[]>(initialFilter);
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();

  // set default time range to last 30 days
  const [timeRange,setTimeRange] = useState<TimeRangeProps>({
    value: 'last30days',
    startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD'), // default is 7 days ago
    endDate: dayjs().format('YYYY-MM-DD') // default is today,
  });

  const handleTimeRangeChange = (newTimeRange: TimeRangeProps) => {
    setTimeRange(newTimeRange);
  };
  
  const shape: NodeShape = AdSet.shape;

  const setPagination = (val) => {
    return _setPagination(val);
  }

  const debouncedSetSearchQuery = useCallback(
    debounce((value) => {
      setSearchQuery(value);
      setPagination((prev) => ({ ...prev, pageIndex: 0 })); // Reset to first page
    }, 500), // 500ms debounce delay
    []
  );

  async function fetchCampaignData() {
    try {
      setDataLoading(true);
      const campaignData = await loadCampaignData({
        searchQuery: searchQuery,
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
        sorting: sorting,
        filters: filterData,
        timeRange
      });
      setDataLoading(false);
      setInstances(campaignData.instances);
      setTotalItems(campaignData.numInstances);
    } catch (error) {
      console.error('Error fetching data:', error);
      setInstances([]);
    }
  }

  useEffect(() => {
    fetchCampaignData();
  },[pagination.pageIndex, pagination.pageSize, sorting, searchQuery, filterData, timeRange])

  const customColumns = useCustomColumns(instances);

  const detailsViewHandler = (row) => {
    navigate(`${location.pathname}/details?uri=${row.id}`);
  }

  
  const handleRefreshData = (isRefreshing: boolean, newData: DynamicObject[]) => {
    if (isRefreshing) {
      const updatedInstances = instances.map((instance) => {
        const newInstance = newData.find((data) => data.id === instance.id);
        return newInstance ? newInstance : instance;
      });
      setInstances(updatedInstances);
    }
  };

  // trigger the fetchAdData function when the brandAccount changes from quickFilter
  const {curAccount} = useContext(DashboardContext);
  const handleBranchAccountChange = (account: string) => {
    setFilterData((prev) => {
      if (!prev) {
        prev = [];
      }
      // set the brandAccount filter if the brandAccount option selected
      if (curAccount && curAccount.name !== undefined) {
        // remove the previous brandAccount filter if it exists
        prev = prev.filter(filter => filter.field !== 'brandAccount');
        // add the new brandAccount filter
        return [...prev, { field: 'brandAccount', value: account, operator: 'equal' }];
      } else {
        return prev.filter(filter => filter.field !== 'brandAccount');
      }
    })
  }

  // trigger the fetchAdData function when the filter changes
  // newFilters values, e.g. newFilters = [{ field: 'shortAdName', value: 'TF-247', operator: 'equal' }]
  // currentFilter, e.g = [{ field: 'status', value: 'On', operator: 'equal' }]
  // newFilter will replace currentFilter or current filter state
  const handleFilterChange = (newFilters: FilterProps[]) => {
    setFilterData(newFilters);
  }

  const handleCheckboxActiveOnly = (checked:boolean) => {
    setFilterData((prev) => {
      if(!prev) {
        prev = [];
      }
      let updatedFilters: FilterProps[] = [];
      if(checked) {
        // get the filter status `On`, to make sure the filter is not duplicated
        const exists = prev.some(filter => filter.field === 'impressions' && filter.value === '0' && filter.operator === 'greater');
        if (!exists) {
          updatedFilters = [...prev, { field: 'impressions', value: '0', operator: 'greater' }];
        } else {
          updatedFilters = prev;
        }
      } else {
        updatedFilters = prev.filter(filter => filter.field !== 'impressions');
      }
      return updatedFilters;
    });

  };

  return (
    <>
       {customColumns || instances?.length === 0 ? (
        <InstanceOverview 
          instances={instances} 
          shape={shape} 
          customColumns={customColumns}
          pagination={pagination}
          totalItems={totalItems}
          initialFilter={filterData}
          filterInstances={(filter: FilterProps[]) => handleFilterChange(filter)}
          onPaginationChange={setPagination} 
          sorting={sorting}
          onRefreshingData={handleRefreshData}
          resetFilter={(value:boolean) => value && setFilterData(initialFilter)}
          onSortingChange={setSorting}
          triggerActionRefresh={fetchCampaignData}
          onSearchChange={debouncedSetSearchQuery}
          isLight={isLight} // Pass isLight to InstanceOverview
          batchActionDropdown={(selectedRowData) => <CampaignActionDropdown
            selectedRowData={selectedRowData}
            // onComplete={fetchCampaignData}
          />}
          isLoading={dataLoading}
          quickFilter={[
            {
              property: 'impressions',
              label: 'Show only ads with impressions',
              onChange: (checked:boolean) => handleCheckboxActiveOnly(checked),
              value: filterData?.find(filter => filter.field === 'impressions')?.value === '0',
              defaultValue: '0'
            },
            {
              property: 'brandAccount',
              onChange: (account:string) => handleBranchAccountChange(account),
              value: curAccount?.name,
            }
          ]}
          onDetailsClick={detailsViewHandler}
          timeRange={timeRange}
          onTimeRangeChange={handleTimeRangeChange}
          // dateFilter={() => (
          //   <DatePickerFilter onDateSelect={setSelectedDate} />
          // )}
        />) : (<Spinner />)}
    </>
      
  )
}

export default CampaignManagement
