import { Suspense, useContext, useEffect, useRef, useState } from 'react';
import TableView from '../components/TableView';
import './DayPartingView.scss';
import styles from './DayPartingView.scss.json';
import { DayPicker } from 'react-day-picker';
import { BrandAccountSelector } from '../components/RefreshQuoraData';
import { useSuspense } from '../utils';
import { BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { DashboardContext } from '../contexts/Dashboard';
import {formatCurrency,formatPercentage} from '../utils/data';
import { QuoraAccount } from 'titan-ads/lib/shapes/QuoraAccount';
import { useScreenshot } from 'use-react-screenshot'

const DayPartingView = () => {
    const initialBrands = useSuspense(BrandAccount.loadAll());
    const [brands] = useState<{ read(): any }>(initialBrands);
    const [selected, setSelected] = useState<Date>(new Date());
    const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
    const [source, setSource] = useState<any[]>([]);
    const { curAccount } = useContext(DashboardContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [campaignBid, setCampaignBid] = useState<any>('');
    const [quoraAccounts, setQuoraAccounts] = useState<QuoraAccount[]>([]);
    const [selectedQuoraAccount, setSelectedQuoraAccount] = useState<QuoraAccount | null>(null);
    const ref = useRef<HTMLDivElement>(null);
    const [image, takeScreenshot] = useScreenshot();
    const [checked, setChecked] = useState<string>('brandAccount');
    const clientQuoraAccount = Array.from(
        new Set(quoraAccounts.filter(
            (account) => !account.name.includes('Semantu') && !account.name.includes('LINCD'))))
            .filter((acc, index, item) => index === item.findIndex((t) => t.quoraId === acc.quoraId));

    const handleCheckbox = (e: any) => {
        const value = e.target.value;
        setChecked(value);
    }

    const getDailyAdsData = async () => {
        try {
            setLoading(true);
            // send params to backend date and what constraint for getting the report (brand or quora account)
            const adData = await Server.call(packageName, 'getDayPartingData', formatDate(selected), checked === 'brandAccount' ? curAccount : selectedQuoraAccount);
            if(adData) setSource(adData.data);
            // alert(`No Ad Matched for Date ${formatDate(selected)} for account ${curAccount.name} - ${adData}`)
            setLoading(false);
        } catch (e) {
            console.log('Error getting daily ad data for report');
            return [];
        }
    };

    function formatAdDataForTable(adDataByDay, advertiserData = []) {
        const formattedData = [];
        adDataByDay.forEach((dataEntry, index) => {
            Object.keys(dataEntry).forEach(key => {
                // const advertiserValue =
                //     advertiserData[index]?.[key] !== undefined
                //         ? advertiserData[index][key]
                //         : '';
    
                const shouldFormatAsPlainNumber = [
                    'ROAS',
                    'Traffic Source (Quora) Clicks',
                    'Tracker (TCG or Voluum?) Clicks',
                    'Advertiser Sales',
                    'Total Ads',
                    'Active Ads (Running)',
                    'Ads w/ Clicks',
                    'Ads w/ Conversions',
                    'Range # of Ads Engaged From Last Week (Min/Max)',
                ].includes(key);

                const colorCoded = [
                    'Todays P/L Peak',
                    'Delta From P/L Peak'
                ];
    
                const shouldFormatAsPercentage = ['Gross Margin', '% of Ads w/ Clicks', '% of Ads w/ Conversions'].includes(key);
    
                formattedData.push({
                    metric: key,
                    quoraPL: key === 'Campaign Bid(s)'
                        ? <input
                            type="text"
                            value={campaignBid}
                            onChange={(e) => setCampaignBid(e.target.value)}
                            className={styles.campaignBids}
                          />
                        : typeof dataEntry[key] === 'number'
                            ? colorCoded.includes(key)
                                ? <span
                                    className={
                                        dataEntry[key] < 0
                                            ? styles.red
                                            : styles.green
                                    }
                                  >
                                    {shouldFormatAsPercentage
                                        ? formatPercentage(dataEntry[key])
                                        : shouldFormatAsPlainNumber
                                            ? dataEntry[key].toLocaleString()
                                            : formatCurrency(dataEntry[key])
                                    }
                                  </span>
                                : shouldFormatAsPercentage
                                    ? formatPercentage(dataEntry[key])
                                    : shouldFormatAsPlainNumber
                                        ? dataEntry[key].toLocaleString()
                                        : formatCurrency(dataEntry[key])
                            : dataEntry[key],
                });
            });
        });
        return formattedData;
    }
    
    const formatDate = (date: Date) => {
      return date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2,'0') + '-' + date.getDate().toString().padStart(2, '0');
        // return date.toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' });
    }

    useEffect(() => {
      if (selected && (curAccount || selectedQuoraAccount)) {
          getDailyAdsData();
      }
    },[selected, curAccount, selectedQuoraAccount, checked]);
    useEffect(() => {

        (async () => {
            const quoraAccount = await QuoraAccount.loadAll();
            quoraAccount?.forEach((account) => {
                setQuoraAccounts((prev) => [...prev, account]);
            });
        })();
        
        if (location.pathname.includes('/view/daily-ad-report')) {
            document.querySelectorAll('.titan_DefaultLayout_main').forEach((element) => {
                (element as HTMLElement).style.maxWidth = 'none';
            });
        }
    }, [location.pathname, selected]);

    return (
      <div className={styles.dailyAdContainer}>
        <h3>Generate Report - Daily Ad View</h3>
        <div className={styles.accountSelector}>
          <h4>Report specificity:</h4>
          <div className={styles.inputReport}>
            <div className={styles.checkbox}>
              <p>Brand Account</p>
              <input type="checkbox" value={'brandAccount'} checked={checked === 'brandAccount'} onChange={handleCheckbox} />
            </div>
            <div className={styles.checkbox}>
              <p>Quora Account</p>
              <input type="checkbox" value={'quoraAccount'} checked={checked === 'quoraAccount'} onChange={handleCheckbox} />
            </div>
          </div>
          {checked === 'brandAccount' && (<>
            <h4>Brand Account:</h4>
            <Suspense fallback={<BrandAccountSelector.Loading />}>
              <BrandAccountSelector qAccounts={brands} showLabel={false} />
            </Suspense>
          </>)}
          {/* select quora Account */}
          {checked === 'quoraAccount' && (<>
            <div className={styles.quoraAccountSelector}>
              <h4>Quora Account:</h4>
              <select
                disabled={checked === 'quoraAccount' ? false : true}
                onChange={(e) => {
                  const selectedAccount = QuoraAccount.getFromURI(e.target.value);
                  setSelectedQuoraAccount(selectedAccount);
                }}
                value={selectedQuoraAccount?.uri}
              >
                {!clientQuoraAccount && (
                  <option>Choose an account</option>
                )}
                {clientQuoraAccount?.map((acc: QuoraAccount) => {
                  return (
                    <option data-key={acc.uri} key={acc.uri} value={acc.uri}>
                      {acc.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </>)}
          <div className={styles.inputDatePicker}>
            <h4>Date Selector:</h4>
            {!showDayPicker ? (
              <input
                type="text"
                readOnly
                value={selected ? formatDate(selected) : 'Select Day'}
                onClick={() => setShowDayPicker(!showDayPicker)}
              />
            ) : (
              <div className={styles.dayPicker}>
                <DayPicker
                  mode="single"
                  required
                  captionLayout="dropdown"
                  hideNavigation
                  timeZone="America/Los_Angeles"
                  selected={selected}
                  onSelect={(date) => {
                    setSelected(date);
                    setShowDayPicker(!showDayPicker);
                  }}
                />
              </div>
            )}
          </div>

          <button className={styles.screenShotbtn} disabled={source.length === 0} onClick={() => {
            takeScreenshot(ref.current).then((image) => {
              const link = document.createElement('a');
              link.href = image;
              link.download = `Daily_AD_Report_${selected}.png`;
              link.click();
            });
          }}>Take screenshot
          </button>
        </div>
        <TableView 
          ref={ref}
          data={formatAdDataForTable(source)}
          reportType='daypartingview'
          isLoading={isLoading}
          account={checked === 'brandAccount' ? curAccount?.name : selectedQuoraAccount?.name} 
          selectedDate={selected}  
        />
      </div>
    );
};

export default DayPartingView;
