import {ShapeSet} from 'lincd/lib/collections/ShapeSet';
import {Suspense, useContext, useEffect, useState} from 'react';
import {AccountCard} from 'titan-ads/lib/components/AccountCard';
import {List} from 'titan-ads/lib/components/List';
import {Ad,AdSet,BrandAccount,Campaign} from 'titan-ads/lib/shapes/TitanShapes';
import {QuoraAccount} from 'titan-ads/lib/shapes/QuoraAccount';
import {CreateNewAccount} from '../components/CreateNewAccount';
import {Spinner} from '../components/Spinner';
import {ThemeContext} from '../contexts/Theme';
import {DefaultLayout} from '../layout/DefaultLayout';
import {useSuspense} from '../utils';
import './Accounts.scss';
import * as styles from './Accounts.scss.json';
import { useAuth } from 'lincd-auth/lib/hooks/useAuth';
import {Server} from 'lincd-server-utils/lib/utils/Server';
import {packageName} from '../package';

export default function Sheets() {

  let account = useAuth().userAccount;
  if(!account.email.includes('@semantu.com')) {
    return null;
  }
  const [pendingItems, setPendingItems] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // fetch data
    setLoading(true);
    Server.call(packageName,'getPendingItems').then(pendingItems => {
      setPendingItems(pendingItems);
      setLoading(false);

    })
  },[]);

  let campaigns, adSets, ads;
  if(pendingItems) {
    campaigns = pendingItems.filter((item) => item instanceof Campaign);
    adSets = pendingItems.filter((item) => item instanceof AdSet);
    ads = pendingItems.filter((item) => item instanceof Ad);
  }

  const removePendingItems = () => {
    setLoading(true);
    confirm('Are you sure you want to remove all pending items?') && Server.call(packageName,'removePendingItems').then(() => {
      setPendingItems([]);
      setLoading(false);
    });
  }

  return (
    <DefaultLayout>
      <h1>Pending items</h1>
      {loading ? <Spinner /> : <>
        {campaigns && campaigns.length > 0 && <h2>${campaigns.length} campaigns</h2>}
        {adSets && adSets.length > 0 && <h2>${adSets.length} ad sets</h2>}
        {ads && ads.length > 0 && <h2>${ads.length} ads</h2>}
        {pendingItems.length > 0 && <a onClick={removePendingItems}>Remove pending items</a>}
        {pendingItems.length === 0 && <p>No pending items</p>}
      </>}
    </DefaultLayout>
  );
}
