import React, { useState } from "react";
import ReactSelect from "react-select";
import { FilterProps } from "../types";
import "./FilterTable.scss";
import style from "./FilterTable.scss.json";
import { Icons } from "./Icons";

type FilterTableProps = {
  fieldOptions: { value: string; label: string }[];
  filters: FilterProps[];
  onApplyFilters?: (filters: FilterProps[]) => void;
};
export const FilterTable = ({ fieldOptions, filters: filtersData, onApplyFilters }: FilterTableProps) => {
  const initialFilter = filtersData.length === 0 ? [{ field: "", operator: "", value: "" }] : filtersData;
  const [filters, setFilters] = useState<FilterProps[]>(initialFilter);

  const handleFilterChange = (index: number, updatedFilter: FilterProps) => {
    const newFilters = [...filters];
    newFilters[index] = updatedFilter;
    setFilters(newFilters);
  };

  const handleRemoveFilter = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index);

    // make sure there is at least one filter
    if (newFilters.length === 0) {
      newFilters.push({ field: "", operator: "", value: "" });
    }

    setFilters(newFilters);
  };

  const handleAddFilter = () => {
    const newFilters = [...filters];

    // make sure the last filter has been filled
    const lastFilter = newFilters[newFilters.length - 1];
    if (!lastFilter.field || !lastFilter.operator) {
      return;
    }
    newFilters.push({ field: "", operator: "", value: "" });
    setFilters(newFilters);
  };

  const handleApplyFilters = () => {
    const activeFilter = filters.filter(
        (filter) => filter.field && filter.operator
    );

    // send active filters to parent component, if onApplyFilters is exist
    if (onApplyFilters) {
      onApplyFilters(activeFilter);
    }
  };

  const disableApplyBtn = !filters.every((filter) => filter.field && filter.operator);

  return (
    <div className={style.root}>
      <div>
        {filters.map((filter, index) => (
          <FilterRow
            key={index}
            filter={filter}
            onFilterChange={(updatedFilter) =>
              handleFilterChange(index, updatedFilter)
            }
            removeFilter={() => handleRemoveFilter(index)}
            fieldOptions={fieldOptions}
          />
        ))}
      </div>
      <div className={style.separator} />
      <div className={style.fieldAction}>
        <button className={style.addBtn} onClick={handleAddFilter} aria-label="Add Filter">
            <Icons.Plus width={"16"} height={"16"} />
        </button>
        <button onClick={handleApplyFilters} disabled={disableApplyBtn} aria-label="Apply Filter">Apply</button>
      </div>
    </div>
  );
};

type FilterRowProps = {
  filter: FilterProps;
  onFilterChange: (filter: FilterProps) => void;
  removeFilter: () => void;
  fieldOptions: { value: string; label: string }[];
};

const FilterRow = ({
  filter,
  onFilterChange,
  removeFilter,
  fieldOptions,
}: FilterRowProps) => {
  const operatorOption = [
    { label: "Not Equal", value: "not_equal" },
    { label: "Smaller", value: "smaller" },
    { label: "Smaller or Equal", value: "smaller_or_equal" },
    { label: "Equals", value: "equal" },
    { label: "Greater", value: "greater" },
    { label: "Greater or Equal", value: "greater_or_equal" },
    { label: "Contains", value: "contains" },
  ];

  const handlePropertyChange = (selectedOption: any) => {
    onFilterChange({ ...filter, field: selectedOption.value });
  };

  const handleOperatorChange = (selectedOption: any) => {
    onFilterChange({ ...filter, operator: selectedOption.value });
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange({ ...filter, value: event.target.value });
  };

  return (
    <div className={style.fieldRow}>
      <p>Where</p>
      <div className={style.fieldSelect}>
        <ReactSelect
            options={fieldOptions}
            value={fieldOptions.find((item) => item.value === filter.field)}
            onChange={handlePropertyChange}
            placeholder="Select Attribute"
            isSearchable={true}
            />
        </div>
        <div className={style.fieldSelect}>
      <ReactSelect
        className={style.fieldSelect}
        options={operatorOption}
        value={operatorOption.find((item) => item.value === filter.operator)}
        onChange={handleOperatorChange}
        placeholder="Select Operator"
        isSearchable={true}
      />
      </div>
      <input
        className={style.fieldInput}
        type="text"
        value={filter.value}
        onChange={handleValueChange}
        placeholder="Value"
      />
      <button className={style.removeBtn} onClick={removeFilter}>
        <Icons.Close width={"20"} height={"20"} />
      </button>
    </div>
  );
};


export const FilterBadge = ({ filter, onRemove }: { filter: FilterProps, onRemove: (filter: FilterProps) => void }) => {
    const operatorMap: { [key: string]: string } = {
        not_equal: "≠",
        smaller: "<",
        smaller_or_equal: "≤",
        equal: "=",
        greater: ">",
        greater_or_equal: "≥",
        contains: "∋",
      };

    return (
        <div className={style.badge}>
            <span>{filter.field}</span>
            <span className={style.operator}>{operatorMap[filter.operator] || filter.operator}</span>
            <span className={style.value}>{filter.value}</span>
            <button onClick={() => onRemove(filter)}>
                <Icons.Close width={"14"} height={"16"} />
            </button>
        </div>
    );
}