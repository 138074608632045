import React, { useEffect, useRef, useState } from 'react';
import { TimeRangeProps, TimeRangeValue, TimeRangeOptions} from '../types';
import { DayPicker } from 'react-day-picker';
import style from './FilterTimeRange.scss.json';
import './FilterTimeRange.scss';
import dayjs from 'dayjs';
import { cl } from 'lincd/lib/utils/ClassNames';
import { Select } from './Select';
import { FaCalendarAlt } from 'react-icons/fa';

interface FilterTimeRangeProps {
    name: string;
    label?: string;
    timeRange: TimeRangeProps;
    timeRangeOptions?: TimeRangeValue[]; // Set the values dynamically
    onHandleChange?: (timeRange: TimeRangeProps) => void;
    variant?: 'default' | 'outline';
}

export function FilterTimeRange({
    name,
    label,
    timeRange,
    timeRangeOptions,
    onHandleChange,
    variant = 'default',
}: FilterTimeRangeProps) {
    // Custom time range state
    // We need this to handle custom time range
    const [customTimeRange, setCustomTimeRange] = useState<TimeRangeProps>({
        value: timeRange?.value,
        startDate: timeRange?.startDate, // Default startDate for last 30 days
        endDate: timeRange?.endDate, // Default endDate is today
    });

    // State to control calendar visibility
    const [isCalendarVisible, setIsCalendarVisible] = useState<boolean>(
        customTimeRange.value === 'customDateRange' || customTimeRange.value === 'customSingleDate'
    );
    const calendarRef = useRef<HTMLDivElement>(null);

    // Handle click outside to close the calendar
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setIsCalendarVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [calendarRef]);

    // Handle time range change
    const handleTimeRangeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const value = e.target.value as TimeRangeOptions;
    
        // Handle other predefined time range options
        let start = '';
        let end = '';
        let singleDate = '';
    
        switch (value) {
            case 'today':
                singleDate = dayjs().format('YYYY-MM-DD');
                break;
            case 'yesterday':
                singleDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
                break;
            case 'last7days':
                start = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last14days':
                start = dayjs().subtract(14, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'currentMonth':
                start = dayjs().startOf('month').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last30days':
                start = dayjs().subtract(30, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last60days':
                start = dayjs().subtract(60, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            case 'last90days':
                start = dayjs().subtract(90, 'day').format('YYYY-MM-DD');
                end = dayjs().format('YYYY-MM-DD');
                break;
            default:
                start = '';
                end = '';
        }
    
        setCustomTimeRange((prev) => {
            let updatedRange;
            if(singleDate) {
              updatedRange = {
                ...prev,
                value,
                date: singleDate,
              };
            } else {
              updatedRange = {
                ...prev,
                value,
                startDate: start,
                endDate: end,
              };

            }
    
            // Remove the `label` property if the value is not `customDateRange`
            if (value !== 'customDateRange' && value !== 'customSingleDate') {
                delete updatedRange.label;
            }
    
            // If not custom, store to the parent component
            if (value !== 'customDateRange' && value !== 'customSingleDate' && onHandleChange) {
                setTimeout(() => {
                    onHandleChange(updatedRange);
                }, 0);
            }
    
            // Update calendar visibility
            setIsCalendarVisible(value === 'customDateRange' || value === 'customSingleDate');
    
            return updatedRange;
        });
    };
    
    const handleDateSelection = (
        selected: { from?: Date; to?: Date } | Date | undefined,
        mode: 'range' | 'single'
    ) => {
        if (mode === 'range') {
            const { from, to } = (selected as { from?: Date; to?: Date }) || {};
            const startDate = from ? dayjs(from).format('YYYY-MM-DD') : '';
            const endDate = to ? dayjs(to).format('YYYY-MM-DD') : '';
            setCustomTimeRange((prev) => ({
                ...prev,
                startDate,
                endDate,
                date:undefined
            }));
        } else {
            const selectedDate = selected as Date | undefined;
            const formattedDate = selectedDate
                ? dayjs(selectedDate).format('YYYY-MM-DD')
                : '';
            setCustomTimeRange((prev) => ({
                ...prev,
                date: formattedDate,
                endDate:undefined,
                startDate:undefined
                // endDate: formattedDate,
            }));
        }
    };    

    // apply custom date range
    const applyDateRange = () => {
        // if startDate and endDate are valid, store to the parent component
        if (customTimeRange && ((customTimeRange.startDate && customTimeRange.endDate) || customTimeRange.date) && onHandleChange) {
           // Create a new object with the updated label
            const updatedTimeRange = {
                ...customTimeRange,
                label: `Custom date${
                    customTimeRange.value === 'customDateRange' && customTimeRange.startDate && customTimeRange.endDate
                        ? ` : from ${customTimeRange.startDate} to ${customTimeRange.endDate}`
                        : customTimeRange.value === 'customSingleDate' && customTimeRange.date
                        ? ` : ${customTimeRange.date}`
                        : ""
                }`,
            };

            // Update the state with the new object
            setCustomTimeRange(updatedTimeRange);

            // Call the parent handler with the updated object
            onHandleChange(updatedTimeRange);
        }

        // close the calendar
        setIsCalendarVisible(false);
    }
    

    // cancel custom date range, set back to default state
    const cancelDateRange = () => {
        setIsCalendarVisible(false);
        setCustomTimeRange((prev) => {
            return { ...prev, value: timeRange?.value };
        });

        // store to the parent component
        // if (onHandleChange) {
        //     onHandleChange({ 
        //         value: 'last7days', 
        //         startDate: dayjs().subtract(7, 'day').format('YYYY-MM-DD'), 
        //         endDate: dayjs().format('YYYY-MM-DD') 
        //     });
        // }
    }

    const toggleCalendarVisibility = (e: React.MouseEvent) => {
        e.stopPropagation(); // Prevents the select dropdown from closing
        setIsCalendarVisible((prev) => !prev);
    };

    let maxDateBack = new Date();
    // Go 32 days back
    maxDateBack.setTime(maxDateBack.getTime() - 32 * 24 * 60 * 60 * 1000);
    
    return (
        <div className={style.timeRange}>
            {label && <label htmlFor={name}>{label}</label>}
            <div className={style.selectWrapper}>
                <Select
                    name={name}
                    className={cl(
                        style.timeRangeSelect, 
                        customTimeRange.value === 'customDateRange' && customTimeRange.label && style.dateRangeWidth, 
                        style[variant])}
                    value={customTimeRange.value || ""}
                    onChange={handleTimeRangeChange}
                >
                    {timeRangeOptions ? (
                        timeRangeOptions.map((option) => {
                            const label =
                                (option.value === 'customDateRange' && customTimeRange.value === 'customDateRange') ||
                                (option.value === 'customSingleDate' && customTimeRange.value === 'customSingleDate')
                                    ? customTimeRange.label || option.label
                                    : option.label;

                            return (
                                <option key={option.value} value={option.value}>
                                    {label}
                                </option>
                            );
                        })
                    ) : (
                        <>
                            <option value="today">Today</option>
                            <option value="yesterday">Yesterday</option>
                            <option value="last7days">Last 7 days</option>
                            <option value="last14days">Last 14 days</option>
                            <option value="currentMonth">Current month</option>
                            <option value="last30days">Last 30 days</option>
                            <option value="customDateRange">
                                {customTimeRange.value === 'customDateRange' && customTimeRange.label || "Custom date range"}
                            </option>
                            <option value="customSingleDate">
                                {customTimeRange.value === 'customSingleDate' && customTimeRange.label || "Custom single date"}
                            </option>
                        </>
                    )}
                </Select>
                {(customTimeRange.value === 'customDateRange' || customTimeRange.value === 'customSingleDate') && (
                <button onClick={toggleCalendarVisibility} aria-label="Toggle calendar">
                    <FaCalendarAlt />
                </button>
                )}
            </div>

            {isCalendarVisible && (
                <div className={style.timeRangeCalendar} ref={calendarRef}>
                    {customTimeRange.value === 'customDateRange' ? (
                        <DayPicker
                            mode="range"
                            disabled={
                                timeRangeOptions?.find(
                                    (option) => option.value === customTimeRange.value
                                )?.disabled || { after: new Date(), before: maxDateBack }
                            }
                            selected={
                                customTimeRange.startDate && customTimeRange.endDate
                                    ? {
                                        from: new Date(customTimeRange.startDate),
                                        to: new Date(customTimeRange.endDate),
                                    }
                                    : undefined
                            }
                            onSelect={(selected) =>
                                handleDateSelection(selected, 'range')
                            }
                        />
                    ) : (
                        <DayPicker
                            mode="single"
                            disabled={{ after: new Date(), before: maxDateBack }}
                            selected={
                                customTimeRange.date
                                    ? new Date(customTimeRange.date)
                                    : undefined
                            }
                            onSelect={(selected) =>
                                handleDateSelection(selected, 'single')
                            }
                        />
                    )}
                    <div className={style.timeRangeAction}>
                        <button className={style.cancelBtn} onClick={cancelDateRange}>Cancel</button>
                        <button onClick={applyDateRange}>Apply</button>
                    </div>
                </div>
            )}
        </div>
    );
}
