import { useEffect, useState } from 'react';
import { Ad } from 'titan-ads/lib/shapes/TitanShapes';
import { formatPercentage, formatDate } from '../utils/data';
import '../components/RefreshQuoraData.scss';
import '../pages/MediaMixReport.scss';
import { default as style } from '../pages/MediaMixReport.scss.json';
import React from 'react';

// Define the structure for sales data by headline
interface SalesByHeadlineProps {
    Headline: string;
    data: {
        id: string;
        ads: Ad[]; // Array of ad data related to the headline
        conversions: any[] | null; // Conversion data associated with the ad
        Sales: number; // Total sales for this entry
        Clicks: number; // Total clicks for this entry
        SalesPercent: string; // Sales percentage as formatted string
        ClicksPercent: string; // Clicks percentage as formatted string
    }[];
}

// Define the structure for headline data source properties
interface HeadlineDataProps {
    conversionsByUniqueId: Map<string, any[]>; // Map of conversion data by unique ID
    adsByUniqueId: Map<string, Ad[]>; // Map of ads by unique ID
    clicks: any; // Click data structure
}

// Main functional component
export default function SalesByHeadline({
    Source, // Data source containing conversions and ads
    StartDate, // Start date object
    showFullReport, // Boolean to toggle showing the full report
    columnValue, // Array of column options, each option can be toggled
    onHeadlineOptionsChange, // Callback to trigger when headline options change
}: {
    Source: HeadlineDataProps;
    StartDate: { dateValue: string };
    showFullReport: boolean;
    columnValue: { label: string; value: string; isChecked: boolean }[];
    onHeadlineOptionsChange: (data: any) => void;
}) {
    // State for headline data based on initial Source data
    const [headlineData, setHeadlineData] = useState<SalesByHeadlineProps[]>([]);
    // State for filtered headline data based on columnValue changes
    const [filteredData, setFilteredData] = useState<SalesByHeadlineProps[]>([]);
    // State for total clicks and sales, including percentages for all data
    const [totals, setTotals] = useState({ clicks: 0, sales: 0, clicksPercent: '0.00%', salesPercent: '0.00%' });
    // State for total clicks and sales across all data (initial)
    const [initialTotals, setInitialTotals] = useState({ clicks: 0, sales: 0, clicksPercent: '0.00%', salesPercent: '0.00%' });
    // State for totals of visible rows (filtered data)
    const [visibleTotals, setVisibleTotals] = useState({ clicks: 0, sales: 0, clicksPercent: '0.00%', salesPercent: '0.00%' });
    // State for data displayed on the table, based on filtering
    const [displayData, setDisplayData] = useState<SalesByHeadlineProps[]>([]);

    // Calculate initial headline data and totals from Source when component mounts or Source updates
    useEffect(() => {
        if (!Source?.adsByUniqueId || !Source?.conversionsByUniqueId) return;

        const newHeadlineData: SalesByHeadlineProps[] = []; // Array to store headline data
        let totalClicks = 0; // Total clicks across all data
        let totalSales = 0; // Total sales across all data

        // Populate headlineData based on unique ad ID
        Source.conversionsByUniqueId.forEach((conversions, uniqueId) => {
            const ads = Source.adsByUniqueId.get(uniqueId) || [];
            if (!ads.length) return;

            // Assign the headline based on the ad's headline description
            const headline = ads[0]?.headlineDescription || 'Unknown';
            const sales = conversions.length; // Number of conversions = sales count
            const clicks = Source.clicks.data.filter((click: any) => click.subid_1.includes(uniqueId)).length;

            // Accumulate total clicks and sales
            totalClicks += clicks;
            totalSales += sales;

            // Entry with data for current unique ID
            const newEntry = {
                id: uniqueId,
                ads,
                conversions,
                Sales: sales,
                Clicks: clicks,
                SalesPercent: formatPercentage((sales / totalSales) * 100),
                ClicksPercent: formatPercentage((clicks / totalClicks) * 100),
            };

            // Find or add group for the headline
            const existingGroup = newHeadlineData.find(entry => entry.Headline === headline);
            existingGroup ? existingGroup.data.push(newEntry) : newHeadlineData.push({ Headline: headline, data: [newEntry] });
        });

        // Set headline data to state and send headline options to parent component
        setHeadlineData(newHeadlineData);
        onHeadlineOptionsChange(newHeadlineData.map(group => ({ label: group.Headline, value: group.Headline })));

        // Set overall totals for clicks and sales
        setTotals({
            clicks: totalClicks,
            sales: totalSales,
            clicksPercent: '100.00%',
            salesPercent: '100.00%',
        });
    }, [Source]);

    // Update display and visible totals when headlineData or columnValue changes
    useEffect(() => {
        if (headlineData.length > 0) {
            // Calculate overall clicks and sales totals
            const totalClicks = headlineData.reduce((sum, group) => sum + group.data.reduce((subSum, entry) => subSum + entry.Clicks, 0), 0);
            const totalSales = headlineData.reduce((sum, group) => sum + group.data.reduce((subSum, entry) => subSum + entry.Sales, 0), 0);

            // Filter headlineData based on columnValue
            const filteredData = headlineData.filter(group =>
                !columnValue.some(option => option.value === group.Headline && option.isChecked)
            );

            setDisplayData(filteredData); // Update displayData with filtered data

            // Calculate visible clicks and sales for filtered data
            const visibleClicks = filteredData.reduce((sum, group) => sum + group.data.reduce((subSum, entry) => subSum + entry.Clicks, 0), 0);
            const visibleSales = filteredData.reduce((sum, group) => sum + group.data.reduce((subSum, entry) => subSum + entry.Sales, 0), 0);

            // Set initial totals for non-filtered dataset
            setInitialTotals({
                clicks: totalClicks,
                sales: totalSales,
                clicksPercent: '100.00%',
                salesPercent: '100.00%'
            });

            // Set visible totals for filtered data
            setVisibleTotals({
                clicks: visibleClicks,
                sales: visibleSales,
                clicksPercent: formatPercentage((visibleClicks / totalClicks)),
                salesPercent: formatPercentage((visibleSales / totalSales))
            });
        }
    }, [headlineData, columnValue]);

    return (
        <table className={style.mediaMixData}>
            <thead>
                <tr>
                    <th>Sub Headline Description</th>
                    {showFullReport && <th>Ad Name</th>}
                    <th>Date Range</th>
                    <th>Clicks</th>
                    <th>Clicks %</th>
                    <th>Sales</th>
                    <th>Sales %</th>
                </tr>
            </thead>
            <tbody>
                {displayData.map((group, index) => {
                    const groupClicks = group.data.reduce((sum, entry) => sum + entry.Clicks, 0);
                    const groupSales = group.data.reduce((sum, entry) => sum + entry.Sales, 0);

                    return (
                        <React.Fragment key={index}>
                            <tr>
                                {!showFullReport && <td colSpan={1}><p>{group.Headline}</p></td>}
                                {!showFullReport && <td><p>{`${formatDate(StartDate.dateValue)} - ${formatDate(new Date().toISOString())}`}</p></td>}
                                <td><p>{groupClicks}</p></td>
                                <td><p>{formatPercentage((groupClicks / initialTotals.clicks))}</p></td>
                                <td><p>{groupSales}</p></td>
                                <td><p>{formatPercentage((groupSales / initialTotals.sales))}</p></td>
                            </tr>
                            {showFullReport && group.data.map((entry, entryIndex) => (
                                <tr key={entryIndex}>
                                    <td>{entry.ads[0]?.headlineDescription}</td>
                                    <td>{entry.ads[0]?.identifier}</td>
                                    <td>{`${formatDate(StartDate.dateValue)} - ${formatDate(new Date().toISOString())}`}</td>
                                    <td>{entry.Clicks}</td>
                                    <td>{formatPercentage(Number(entry.ClicksPercent))}</td>
                                    <td>{entry.Sales}</td>
                                    <td>{formatPercentage(Number(entry.SalesPercent))}</td>
                                    <td colSpan={3}><strong>Total for {group.Headline}</strong></td>
                                </tr>
                            ))}
                        </React.Fragment>
                    );
                })}
                <tr>
                    <td colSpan={showFullReport ? 3 : 2}><strong>Overall Total</strong></td>
                    <td><strong>{visibleTotals.clicks}</strong></td>
                    <td><strong>{visibleTotals.clicksPercent}</strong></td>
                    <td><strong>{visibleTotals.sales}</strong></td>
                    <td><strong>{visibleTotals.salesPercent}</strong></td>
                </tr>
            </tbody>

        </table>
    );
}
