// src/components/AdActionDropdown.tsx
import React from 'react';
import ActionDropdown from './ActionDropdown';
import { useBatchActions } from '../hooks/useBatchActions'; // Custom hook to handle actions

interface AdActionDropdownProps {
  selectedRowData: any[]; // Accept selectedRows as a prop
  onComplete?: () => void;
}
const AdActionDropdown: React.FC<AdActionDropdownProps> = ({ selectedRowData, onComplete }) => {
  const { adBatchActions } = useBatchActions();

  return <ActionDropdown actions={adBatchActions} selectedRowData={selectedRowData} onComplete={onComplete}/>;
};


export default AdActionDropdown;
