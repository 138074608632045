import React, { useEffect, useState, useContext, Suspense } from 'react';
import { Ad, BrandAccount } from 'titan-ads/lib/shapes/TitanShapes';
import { ThemeContext } from '../contexts/Theme';
import { BrandAccountSelector } from '../components/RefreshQuoraData';
import { useSuspense } from '../utils';
import DatePickerInput from '../components/DatePickerInput';
import '../components/RefreshQuoraData.scss';
import '../components/TableView.scss';
import stylesTable from '../components/TableView.scss.json';
import './MediaMixReport.scss';
import { default as style } from './MediaMixReport.scss.json';
import { cl } from 'lincd/lib/utils/ClassNames';
import SalesByDevice from '../components/SalesByDevice';
import SalesByHeadline from '../components/SalesByHeadline';
import { DashboardContext } from '../contexts/Dashboard';

interface LoadingState {
    success: boolean;
    message: string;
    state: boolean;
}

interface ShowHideColumn {
    label: string;
    value: string;
    isChecked: boolean;
}

export default function MediaMixReport() {
    const { isLight } = useContext(ThemeContext);
    const [isLoading, setIsLoading] = useState<LoadingState>({
        success: false,
        message: '',
        state: false,
    });
    const [startDate, setStartDate] = useState({ dateValue: '', customDate: false });
    const initialBrands = useSuspense(BrandAccount.loadAll());
    const [cakeData, setCakeData] = useState<any>(null);
    const [brands, setBrands] = useState<{ read(): any }>(initialBrands);
    const { curAccount } = useContext(DashboardContext);
    const [showFullReport, setShowFullReport] = useState<boolean>(false);
    
    const [options, setOptions] = useState<ShowHideColumn[]>([
        { label: 'mobile', value: 'Mobile', isChecked: true },
        { label: 'desktop', value: 'Desktop', isChecked: true },
        { label: 'email', value: 'Email', isChecked: true },
    ]);
    const [displayValue, setDisplayValue] = useState<ShowHideColumn[]>([
        { label: 'mobile', value: 'Mobile', isChecked: true },
        { label: 'desktop', value: 'Desktop', isChecked: true },
        { label: 'email', value: 'Email', isChecked: true },
    ]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

    const handleCheckboxChange = (option: ShowHideColumn) => {
        // Update displayValue based on checkbox changes
        setDisplayValue((prev) => {
            if (prev.some((item) => item.value === option.value)) {
                return prev.filter((item) => item.value !== option.value);
            } else {
                return [...prev, option];
            }
        });

        // Update options based on checkbox changes
        setOptions((prevOptions) =>
            prevOptions.map((opt) =>
                opt.value === option.value
                    ? { ...opt, isChecked: !opt.isChecked }
                    : opt
            )
        );
    };


    // UX Enhancement: Skeleton Loader
    useEffect(() => {
        const toggleSkeletonClass = (elements: NodeListOf<Element>, add: boolean) => {
            elements.forEach((element) => {
                (element as HTMLElement).className = add ? style.skeleton : '';
            });
        };
        const tableStrong = document.querySelectorAll(
            'table > tbody > tr > td > p, ' +
            'table > tr > strong, ' +
            'table > tbody > tr > td > Wrap > strong, ' + 
            'table > tbody > tr > td > Wrap > p, ' + 
            'table > tbody > tr > td > strong'
        );
        toggleSkeletonClass(tableStrong, isLoading.state);
    }, [cakeData, isLoading.state, options]);

    return (
        <div className={cl(style.MediaMixContainer, { [style.darkMode]: !isLight })}>
            <div className={style.mediaInfoContainer}>
                <h3>Generate Report - Media Mix</h3>
            </div>
            <Suspense fallback={<BrandAccountSelector.Loading />}>
                <BrandAccountSelector
                    qAccounts={brands}
                    showLabel={false}
                />
            </Suspense>
            <DatePickerInput
                handleStartDate={(date: string, custom: boolean) =>
                    setStartDate({ dateValue: date, customDate: custom })}
            />
            <div className={style.multiSelectDropdown}>
                {isDropdownOpen && (
                    <div className={style.dropdownMenu}>
                        {options.map((option) => (
                            <label key={option.value} className={style.dropdownOption}>
                                <input
                                    type="checkbox"
                                    checked={option.isChecked} // Use checked status from options
                                    onChange={() => handleCheckboxChange(option)}
                                />
                                {option.value}
                            </label>
                        ))}
                    </div>
                )}
                <div className={style.dropdownHeader} onClick={toggleDropdown}>
                    {options.filter(option => option.isChecked).length > 0 
                        ? options.filter(option => option.isChecked).map(item => item.value).join(', ') 
                        : 'Hide/Show Row'}
                </div>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={showFullReport} onChange={() => setShowFullReport(!showFullReport)} />
                    Show Full Report
                </label>
            </div>
            <SalesByDevice
                StartDate={startDate}
                CakeData={(value: any) => setCakeData(value)}
                onLoading={(value: any) => setIsLoading(value)}
                showFullReport={showFullReport}
                columnValue={displayValue}
            />
            <h3>1st Rebill Rate</h3>
          <table className={stylesTable.table}>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>11/01/24</td>
                  <td>55.3%</td>
                </tr>
              </tbody>
          </table>
            <SalesByHeadline
                Source={cakeData}
                StartDate={startDate}
                // onLoading={isLoading}
                showFullReport={showFullReport}
                columnValue={displayValue}
                onHeadlineOptionsChange={(data: any) => {
                    const headlineData = data.map((item: any) => ({
                        label: item.label,
                        value: item.value
                    }));
                    setOptions((prev) => {
                        const newOptions = [...prev];
                        headlineData.forEach((headline) => {
                            const existing = newOptions.find(opt => opt.value === headline.value);
                            if (!existing) {
                                newOptions.push({ ...headline, isChecked:true});
                            }
                        });
                        return newOptions;
                    });
                }}
            />
        </div>
    );
}
