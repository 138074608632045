import React,{useEffect,useState} from 'react';
import {DefaultLayout} from '../layout/DefaultLayout';
import {Link, useLocation} from 'react-router-dom';
import './CampaignManagementDetail.scss';
import ShapeDetail from '../components/ShapeDetail';
import { QuickFilter, QuickFilters, TimeRangeProps } from '../types';
import './OptimizationReport.scss';
import style from './OptimizationReport.scss.json';
import { Server } from 'lincd-server-utils/lib/utils/Server';
import { packageName } from '../package';
import { ROUTES } from '../routes';
import { FilterTimeRange } from '../components/FilterTimeRange';
import { AdSetDetails } from './AdSetManagementDetail';
import { DynamicObject } from '../utils';

function OptimizationReportDetail()
{
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const uri = queryParams.get('uri');
  const state = location.state.row
   const [adsetData, setAdsetData] = useState<DynamicObject>();
  const [reportDetails,setReportDetails] = useState<any>();
  const id = uri?.split('/').pop();

  useEffect(() => {
    const fetchReportDetails = async () => {
      try {
        const reportDetails = await Server.call(packageName, 'loadExecutableAction', state.id);
        if (reportDetails) setReportDetails(reportDetails);
      } catch (e) {
        console.error(e);
      }
    };
    fetchReportDetails();
  }, [state]);


  const fetchSingleAdSetData = async () => {
      if (!uri) return;
      try {
        const data = await Server.call(packageName, 'loadSingleAdSetData', state.id);
        console.log('data',data)
        if (data) {
          setAdsetData(data);
        } else {
          setAdsetData(null);
        }
      } catch (error) {
        console.error('Error fetching AdSet data:', error);
        setAdsetData(null);
      }
    };
  
    useEffect(() => {
      if(state.objects){
      fetchSingleAdSetData();
      }
    }, [state]);



  return (
    <DefaultLayout>
      <h1><Link to={ROUTES.views.path}>Views</Link> &raquo; <Link to={ROUTES.view_detail.path.replace(':detail','optimization-report')}>Optimization Report</Link> &raquo; {id}</h1>
      {reportDetails && (
        <>
        <div className={style.approvalDetails}>
          <h2>Approved actions</h2>
        </div>
        <ShapeDetail data={reportDetails} skip={['objects']} />
        {adsetData && (
         <>
           <h2>Applied to</h2>
           <AdSetDetails id={state.id} data={adsetData} />
         </>
        )}
        </>
      )}
      </DefaultLayout>
  );
}
export default OptimizationReportDetail;