import {PageCard} from '../components/PageCard';
import {DefaultLayout} from '../layout/DefaultLayout';
import {ROUTES} from '../routes';
import './Admin.scss';
import * as styles from './Admin.scss.json';
import {useAuth} from 'lincd-auth/lib/hooks/useAuth';

export default function Admin() {
  const adminRoutes = Object.values(ROUTES).filter((r) =>
    r.path.startsWith('/admin/'),
  );
  const auth = useAuth();
  const {userAccount} = auth;
  return (
    <DefaultLayout>
      <h1>Admin</h1>
      <div className={styles.container}>
        {adminRoutes.map((route) => {
          //some routes are only for system admins and then they need to be logged in with a semantu email
          if(!route.systemAdminOnly || userAccount?.email.includes('@semantu.com'))
          {
            return <PageCard route={route.path} icon={route.icon} label={route.label} />
          }
        })}
      </div>
    </DefaultLayout>
  );
}
