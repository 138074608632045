/**
 * This component to showing the list table Ads or AdSet in the Detail page Campaign or AdSet
 */
import React, { useCallback, useState, useEffect } from 'react';
import { InstanceOverview } from './InstanceOverview';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { DynamicObject, TitanQueryConfig, loadAdData, loadAdSetData } from '../utils';
import { Ad, AdSet, Campaign } from 'titan-ads/lib/shapes/TitanShapes';
import { useCustomColumns } from '../hooks/useCustomColumns';
import { Spinner } from './Spinner';

interface AdChildrenProps {
    id: string;
    shapeType: 'adSet' | 'campaign';
    label: string;
}
export function AdChildren({ id, shapeType, label }: AdChildrenProps) {
    const [sorting, setSorting] = useState<TitanQueryConfig['sortBy'][]>([]);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [totalItems, setTotalItems] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [data, setData] = useState<DynamicObject[]>();
    const [filtersData, setFiltersData] = useState([]);

    const navigate = useNavigate();

    const debouncedSetSearchQuery = useCallback(
        debounce((value) => {
            setSearchQuery(value);
            setPagination((prev) => ({ ...prev, pageIndex: 0 }));
        }, 500),
    []);

    async function fetchData() {
        try {
            let fetchedData;
            if (shapeType === 'adSet') {
                fetchedData = await loadAdData({
                    searchQuery: searchQuery,
                    filters: [{
                        shapeField: 'adSet',
                        value: AdSet.getFromURI(id)
                    }, ...filtersData],
                    pageSize: pagination.pageSize,
                    pageIndex: pagination.pageIndex,
                    sorting: sorting,
                });
            } else if (shapeType === 'campaign') {
                fetchedData = await loadAdSetData({
                    searchQuery: searchQuery,
                    filters: [{
                        shapeField: 'campaign',
                        value: Campaign.getFromURI(id)
                    }],
                    pageSize: pagination.pageSize,
                    pageIndex: pagination.pageIndex,
                    sorting: sorting,
                });
            }
            
            // set data and total items
            setData(fetchedData.instances);
            setTotalItems(fetchedData.numInstances);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchQuery]);

    const detailViewHandler = (row) => {
        if (shapeType === 'adSet') {
            navigate(`/view/ad-management/details?uri=${row.id}`);
        } else if (shapeType === 'campaign') {
            navigate(`/view/adset-management/details?uri=${row.id}`);
        }
    }

    const shape = Ad.shape;
    const customColumns = useCustomColumns(data);

    return (
        <>
            {(data && customColumns) ? (
                <div>
                    <h2>{label}</h2>
                    <InstanceOverview
                        instances={data}
                        shape={shape}
                        triggerActionRefresh={fetchData}
                        customColumns={customColumns}
                        pagination={pagination}
                        totalItems={totalItems}
                        onPaginationChange={setPagination}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearchChange={debouncedSetSearchQuery}
                        isLight={true}
                        onDetailsClick={detailViewHandler}
                        filterInstances={(filters: any) => {
                            setFiltersData(filters);
                        }}
                    />
                </div>
            ) : <Spinner />}
        </>
    )
}