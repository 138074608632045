import {NamedNode} from 'lincd/lib/models';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {AdSet, Campaign, BaseShape} from 'titan-ads/lib/shapes/TitanShapes';
import {
  AdInputPartial,
  InputTitle,
  InputType,
  QuoraAdDelivery,
  QuoraDeviceAndBrowser,
  QuoraPlacement,
  QuoraTargetingType,
} from 'titan-ads/lib/types';
import {
  getInputPartial,
  getInputsByPlatform,
  getPartialKey,
  getPartialKeyByTitle,
} from 'titan-ads/lib/utils';
import {InputsContext} from '../contexts/AdInputs';
import {
  DefaultAdSetDropdownOption,
  DefaultAdSetDropdownOptions,
  DropdownState,
  GroupID,
} from '../types';
import {AdInput} from './AdInput';
import {Audiences} from './AdSet/Audiences';
import {BroadTargeting as Broad} from './AdSet/Broad';
import {Keywords} from './AdSet/Keywords';
import {Locations} from './AdSet/Locations';
import {Questions} from './AdSet/Questions';
import {Topics} from './AdSet/Topics';
import {Answers} from './AdSet/Answers';
import './InputGroup.scss';
import * as styles from './InputGroup.scss.json';
import {PropertyShape} from 'lincd/lib/shapes/SHACL';

type AdSetDropdown = AdSet | DefaultAdSetDropdownOption;

interface AdSetColumnProps {
  groupId: GroupID;
  handleNextPriority: (
    curPriority?: number,
    setToCurPriority?: boolean,
  ) => void;
  // For if the input group was duplicated
  initialAdSet?: AdSetDropdown;
  initialCampaign?: Campaign;
}

export default function AdSetColumn({
  groupId,
  handleNextPriority,
  initialAdSet = 'Choose an adset',
  initialCampaign,
}: AdSetColumnProps) {
  const [dropdownState, setDropdownState] = useState<DropdownState>(
    initialAdSet === 'Choose an adset' ? 'not_chosen' : 'chosen',
  );
  const [selectedAdSet, setSelectedAdSet] =
    useState<AdSetDropdown>(initialAdSet);

  const {
    handleEditAdSet,
    handleEditInput,
    handleMarkAsCreatingAdSet,
    inputGroups,
  } = useContext(InputsContext);
  const {adSet, campaign, creatingAdSet, inputs, pasted, platform} =
    inputGroups[groupId];

  const deviceBrowserKey = getPartialKeyByTitle(platform, 'Device & Browser');
  const deviceBrowser = inputs[deviceBrowserKey] as QuoraDeviceAndBrowser;

  // const targetingThemeKey = getPartialKeyByTitle(platform, 'Targeting Theme');
  // const targetingTheme = inputs[targetingThemeKey];

  const placementKey = getPartialKeyByTitle(platform, 'Placement');
  const placement = inputs[placementKey] as QuoraPlacement;

  // When the user selects an option from the AdSet dropdown
  useEffect(() => {
    // Didn't choose a default option
    const choseAnyAdSet = selectedAdSet instanceof AdSet;

    if (selectedAdSet === 'Create a new adset') {
      handleMarkAsCreatingAdSet(groupId);

      const newAdSet = new AdSet(campaign, {final: false});
      handleEditAdSet(groupId, newAdSet);
      setSelectedAdSet(newAdSet);
    }

    if (choseAnyAdSet) {
      setDropdownState('chosen');
      handleEditAdSet(groupId, selectedAdSet);
      handleNextPriority(1);
    }
  }, [selectedAdSet]);

  // When the user changes campaign
  useEffect(() => {
    if (!pasted || (pasted && !campaign.equals(initialCampaign))) {
      setDropdownState('not_chosen');
      setSelectedAdSet('Choose an adset');
    }
  }, [campaign]);

  // When the user changes placement or device & browser dropdown
  // in this current input group
  useEffect(() => {
    if (creatingAdSet && selectedAdSet instanceof AdSet) {
      handleEditInput(
        groupId,
        getInputPartial(platform, 'Placement'),
        placement,
      );
      handleEditInput(
        groupId,
        getInputPartial(platform, 'Device & Browser'),
        deviceBrowser,
      );

      // handleEditInput(
      //   groupId,
      //   getInputPartial(platform, 'Targeting Theme'),
      //   targetingTheme,
      // );

      if (deviceBrowser) {
        selectedAdSet.deviceAndBrowser = deviceBrowser;
      }
      if (placement) {
        selectedAdSet.placement = placement;
      }
    }
  }, [deviceBrowser, placement]);

  const adSetOptions: AdSetDropdown[] = [
    'Choose an adset',
    'Create a new adset',
    ...campaign.adSets.filter((adSet) => !adSet.isTemplate),
  ];

  const handleAdSetChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newAdSetName = e.target.value as DefaultAdSetDropdownOption | string;

    if (
      DefaultAdSetDropdownOptions.includes(
        newAdSetName as DefaultAdSetDropdownOption,
      )
    ) {
      setSelectedAdSet(newAdSetName as DefaultAdSetDropdownOption);
    } else {
      setSelectedAdSet(
        adSetOptions.find((a) => a instanceof AdSet && a.identifier === newAdSetName),
      );
    }
  };

  const displayAdSetOption = (opt: AdSetDropdown) => {
    if (dropdownState !== 'not_chosen' && opt === 'Choose an adset') return;
    if (creatingAdSet && opt === 'Create a new adset') return;

    if (!(opt instanceof AdSet)) {
      return <option key={opt.toString()}>{opt.toString()}</option>;
    }

    opt = opt as AdSet;
    return <option key={opt.identifier}>{opt.identifier}</option>;
  };

  // Decide what input fields to show
  const byAdSetColumnAndNotInternal = (p: AdInputPartial) =>
    !p.internal && p.column === 'adset';
  let adSetInputs: AdInputPartial[] = [];
  if (dropdownState === 'chosen' && !creatingAdSet) {
    adSetInputs = getInputsByPlatform(platform).filter(
      byAdSetColumnAndNotInternal,
    );
  } else if (creatingAdSet) {
    const byAdSetCreationOptions = (p: AdInputPartial) =>
      (
        ['Device & Browser', 'Placement', 'Targeting Theme'] as InputTitle[]
      ).includes(p.title);
    adSetInputs = getInputsByPlatform(platform).filter(
      byAdSetColumnAndNotInternal,
    ); //.filter(byAdSetCreationOptions);
  }

  const chosePreexistingAdSet =
    !creatingAdSet && selectedAdSet instanceof AdSet && adSet;

  const targetingType = inputs[
    getPartialKeyByTitle(platform, 'Targeting Type')
  ] as QuoraTargetingType;

  return (
    <>
      <div className={`${styles.adset} ${styles.required}`}>
        <label>AdSet Code</label>
        <select
          disabled={creatingAdSet}
          onChange={handleAdSetChange}
          value={
            selectedAdSet instanceof AdSet ? selectedAdSet.identifier : selectedAdSet
          }
        >
          {adSetOptions.map(displayAdSetOption)}
        </select>
      </div>
      {chosePreexistingAdSet &&
        adSetInputs.map((p) => {
          let value: InputType;
          let propShape: PropertyShape;

          if (p.shapePath instanceof NamedNode) {
            const propShapes = AdSet.shape
              .getPropertyShapes()
              .addFrom(BaseShape.shape.getPropertyShapes());
            propShape = propShapes.find((ps) => ps.path === p.shapePath);
            value = selectedAdSet[propShape.label];
          }
          const key = `${campaign.identifier}-${selectedAdSet.identifier}-${getPartialKey(
            p,
          )}`;

          if (p.title === 'AdSet') return;

          if (!value && p.checked === undefined) {
            value = 'N/A';
          }

          return (
            <div key={key} className={styles.adset}>
              <b>{p.title}:</b> {value.toString()}
            </div>
          );
        })}
      {creatingAdSet &&
        adSetInputs.map((p) => {
          if (!(selectedAdSet instanceof AdSet)) {
            return;
          }

          let className = styles.adset;
          if (p.required) {
            className += ` ${styles.required}`;
          }

          const key = `${campaign.identifier}-${selectedAdSet.identifier}-${getPartialKey(
            p,
          )}`;

          let initialValue;
          if (p.title === 'Placement') {
            initialValue = selectedAdSet.placement;

            // "Ads within a campaign where an objective is set to App
            // Install, Video Views, or Lead Generation are not eligible to
            // appear in Quora Digest emails" - Quora Dashboard
            switch (true) {
              case campaign.conversionObjective === 'App installs':
              case campaign.conversionObjective === 'Video views':
              // case campaign.conversionObjective === 'Lead generation':
              // "Ads within an ad set where delivery is being optimized for
              // conversions or impressions are not eligible to appear in
              // Quora Digest emails" - Quora Dashboard
              case adSet.adDelivery === 'Cost per 1000 impressions':
              case adSet.adDelivery === 'Optimise for conversions':
                p.options = p.options.filter(
                  (option: QuoraPlacement) => option !== 'Digest Emails',
                );
              default:
                break;
            }
          } else if (p.title === 'Device & Browser') {
            initialValue = selectedAdSet.deviceAndBrowser;

            switch (true) {
              case campaign.conversionObjective === 'App installs':
                p.options = p.options.filter(
                  (option: QuoraDeviceAndBrowser) =>
                    !option.includes('Desktop'),
                );
              default:
                break;
            }
          }
          if (
            adSet.targetsMales ||
            adSet.targetsFemales ||
            adSet.targetsUnknown
          ) {
            console.log(
              'Adset Target Male',
              adSet.targetsMales,
              'Adset Target Female',
              adSet.targetsFemales,
              'Adset Target Uknown',
              adSet.targetsUnknown,
            );
          }

          if (p.title === 'Tracker URLs') {
            initialValue = null;
          }

          if (p.title === 'Ad Delivery') {
            // Cost per 1000 impressions is an option for all conversion
            // objectives, and Cost per click is available for all objectives
            // bar Video Views
            const deliveryOptions: QuoraAdDelivery[] = [
              'Cost per click',
              'Cost per 1000 impressions',
            ];

            switch (campaign.conversionObjective) {
              case 'App installs':
              case 'Awareness':
              case 'Conversions':
                deliveryOptions.push('Optimise for conversions');
                break;
              // case 'Lead generation':
              //   deliveryOptions.push('Optimise for leads');
              //   break;
              case 'Video views':
                deliveryOptions.shift(); // Remove 'Cost per click' for video views
                deliveryOptions.push('Optimise for video views');
              default: // Either traffic, or an unknown objective
                break;
            }

            p.options = deliveryOptions;

            if (adSet.placement === 'Digest Emails') {
              p.options = p.options.filter(
                (option: QuoraAdDelivery) =>
                  option !== 'Optimise for conversions' &&
                  option !== 'Cost per 1000 impressions',
              );
            }
          }

          // "Ads within an ad set targeting questions or keywords are not
          // eligible to appear in Quora Digest emails" - Quora Dashboard
          if (
            p.title === 'Targeting Type' &&
            adSet.placement === 'Digest Emails'
          ) {
            p.options = p.options.filter(
              (option: QuoraTargetingType) =>
                option !== 'Contextual Questions' &&
                option !== 'Contextual Keywords',
            );
          }

          // All components which interact with Quora's API
          switch (true) {
            case p.title === 'Excluded Locations':
            case p.title === 'Included Locations':
              return (
                <Locations label={p.title} groupId={groupId} partial={p} />
              );
            case p.title === 'Targeting Description': {
              if (targetingType === 'Audience')
                return (
                  <Audiences className={`${styles.adset} ${styles.required}`} />
                );
              else if (targetingType === 'Broad')
                return <Broad groupId={groupId} />;
              else if (targetingType === 'Behavioral Question history') {
                return (
                  <Questions
                    groupId={groupId}
                    partial={p}
                    includeTimeWindow={true}
                  />
                );
              } else if (targetingType === 'Contextual Questions') {
                return <Questions groupId={groupId} partial={p} />;
              } else if (targetingType === 'Behavioral Keyword history') {
                return (
                  <Keywords
                    groupId={groupId}
                    partial={p}
                    includeTimeWindow={true}
                  />
                );
              } else if (targetingType === 'Contextual Keywords') {
                return <Keywords groupId={groupId} partial={p} />;
              }

              switch (true) {
                case targetingType === 'Behavioral Interests':
                case targetingType === 'Contextual Topics':
                  return <Topics groupId={groupId} partial={p} />;
              }
              if (targetingType === 'Behavioral Answer history') {
                return (
                  <Answers
                    groupId={groupId}
                    partial={p}
                    includeTimeWindow={true}
                  />
                );
              }
            }
          }

          return (
            <AdInput
              className={className}
              groupId={groupId}
              handleNextPriority={handleNextPriority}
              id={getPartialKey(p)}
              inputData={p}
              initialValue={initialValue}
              key={key}
            />
          );
        })}
    </>
  );
}
